import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

class CimsUserDetail {

    /**
    * 생성자
    */
    constructor() {

    }

    /**
     * 사용자 상세 정보 조회
     * @param {String} emplID 
     * @returns 
     */
    getUserDetail(emplID) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.UserInfoBiz';
            request.methodId = 'GetCIMSUserDetail';
            request.parameters.emplId = emplID

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * CIMS 사용자 정보 저장
     * @param {String} emplId 
     * @param {String} userData 
     * @param {String} projects 
     * @returns 
     */
    saveCimsUser(emplId, userData, projects) {
        return new Promise((resolve, reject) => {
                
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.UserInfoBiz'
            request.methodId = 'SaveCIMSUser'
            request.parameters.emplId = emplId
            request.parameters.userData = userData
            request.parameters.projects = projects

            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }
}
export const cimsUserDetail = new CimsUserDetail();