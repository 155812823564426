<template>
    <div class="admin-content">
        <h3>사용자관리</h3>
        <div class="flex-wrap">
            <div class="col-3">
                <div class="form-wrap1">
                    <div>
                        <label>아이디 <i class="required">*</i></label>
                        <div>
                            <div :class="!state.dataTable.isEdit ? 'form-btn-box' : ''">
                                <input class="input-text mr10" ref="emplID" v-if="state.dataTable.isEdit" type="text" v-model.trim="state.dataTable.cimsUserDetail.EmplID" disabled />
                                <input class="input-text mr10" ref="emplID" v-else type="text" v-model.trim="state.dataTable.cimsUserDetail.EmplID" @change="methods.changeEmplID" maxlength="50"/>
                                <div class="btn-wrap" v-show="!state.dataTable.isEdit">
                                    <button class="btn-box"  @click="methods.duplicateEmplID" >중복체크</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label>비밀번호 <i class="required">*</i></label>
                        <div>
                            <input class="input-text mr10" type="password" ref="password" v-model.trim="state.dataTable.cimsUserDetail.Password" maxlength="225"/>
                        </div>
                    </div>
                    <div>
                        <label>성명</label>
                        <div>
                            <input class="input-text mr10" type="text" ref="emplName" v-model.trim="state.dataTable.cimsUserDetail.EmplName" maxlength="30" />
                        </div>
                    </div>
                    <div>
                        <label>사용여부</label>
                        <div>
                            <div class="select-box">
                                <select id="selectUseUn"  v-model="state.dataTable.cimsUserDetail.UseYN">
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="flex-wrap">
                    <div>						
                        <div class="sch-wrap3">
                            <div>
                                <label>관리사업</label>
                                <div>
                                    <input class="input-text mr10" type="text" v-model="state.parameter.keyword" @keyup.enter="methods.getProject" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ml10">
                        <div class="btn-wrap">
                            <button class="btn-box" @click="methods.getProject" >조회</button>
                        </div>
                    </div>
                </div>

                <div class="total-box">
                    <p>Total <strong>{{state.dataTable.projectList === null ? 0 : state.dataTable.projectList.length}}</strong></p>
                </div>

                <DataTable 
                    class="p-datatable-customers" 
                    v-model:selection="state.dataTable.projectDetail"
                    data-key="idx"
                    columnResizeMode="expand"
                    scrollHeight="497px" 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :value="state.dataTable.projectList"
                    :paginator="true"
                    :rowHover="true"
                    :rows="5"
                    :resizableColumns="true">

                    <template #empty>
                        데이터가 없습니다.
                    </template>
                
                    <Column selectionMode="multiple" style="width:10px;" class="tc"></Column>
                    <Column field="idx" header="인덱스" :hidden="true"></Column>
                    <Column field="pname" header="프로젝트명" style="width:400px;" headerClass="tc"></Column>
                </DataTable>

                <div class="flex-wrap right">
                    <div class="btn-wrap">
                        <button class="btn-box gray" @click="methods.saveCimsUser" >저장</button>
                        <button class="btn-box red" v-if="state.dataTable.isEdit" @click="methods.deleteCimsUser" >삭제</button>
                        <button class="btn-box" @click="methods.getCimsUserList" >취소</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { cimsUserDetail } from '@/modules/cims/CIM_CM0131E.js';
import { common } from '@/modules/common/common.js';
import { useRoute } from 'vue-router';
import router from '@/router'
import alertManager from '@/utils/alert-confirm-manager';
export default {
    name: 'CIM_CM0131E',
    setup() {
        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const emplID = ref(); // 아이디
        const password = ref(); // 비밀번호
        const emplName = ref(); // 성명

        // 라우트
        const vrt = useRoute();

        // state
        const state = reactive({
            parameter: {

                // 사용자 아이디
                emplId: vrt.query.EmplID == null ? '' : vrt.query.EmplID,

                // 프로젝트 검색 키워드
                keyword: '',
            },
            dataTable: {
                // 사용자 상세 정보
                cimsUserDetail: new Object(),

                // 프로젝트 상세 정보
                projectDetail: new Array(),

                // 수정여부
                isEdit: vrt.query.EmplID == null ? false : true,

                // 프로젝트 목록
                projectList: null,

                // 아이디 중복여부
                isDuplicate: false,
            },
        });

        // 메서드
        const methods = {
            
            // 사용자 상세 정보 조회 메서드
            getCimsUserDetail: () => {
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                cimsUserDetail.getUserDetail(state.parameter.emplId).then(result => {
                    if (!!result.Table.rows.length) {

                        state.dataTable.cimsUserDetail = result.Table.rows[0];
                    } else {
                        state.dataTable.cimsUserDetail.UseYN = "Y";
                    }
                    state.dataTable.projectDetail = result.Table1.rows;
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                    state.dataTable.isDuplicate = false;
                });
            },

            // 프로젝트 목록 조회 메서드
            getProject: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                common.GetProjectSelect(state.parameter.keyword).then(result => {
                    state.dataTable.projectList = result;
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 사용자 정보 프로젝트 정보 저장
            saveCimsUser: () => {

                // 유효성검사
                if (methods.userValidation()) {

                    // 아이디 중복확인
                    if (state.dataTable.isEdit == false && state.dataTable.isDuplicate == false) {
    
                        alertManager.alert.warning("아이디 중복확인을 해주세요.");
                    }
                    else {
    
                        // 값이 없는 프로젝트 배열 삭제
                        state.dataTable.projectDetail.forEach(function (value, index) {
                            if (!state.dataTable.projectDetail[index]["idx"]) {
                                state.dataTable.projectDetail.splice(index, 1);
                            }
                        })
        
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", true);
        
                        cimsUserDetail.saveCimsUser(state.dataTable.cimsUserDetail.EmplID, JSON.stringify(state.dataTable.cimsUserDetail), JSON.stringify(state.dataTable.projectDetail)).then((response) => {
                            if (response.success) {
        
                                alertManager.toast.save();
                                
                            }
                        }).finally(() => {
        
                            // 스토어 값 변경
                            store.commit("setLoadingStatus", false);

                            //사용자 조회 화면으로 이동
                            router.push({ name: 'CIM_CM0130E', query: null });
        
                        });
                    }
                }
            },

            // 사용자 정보 삭제
            deleteCimsUser: () => {
                state.dataTable.cimsUserDetail.UseYN = "N";
                methods.saveCimsUser();
                methods.getCimsUserList();
            },

            // 목록으로 돌아가기
            getCimsUserList: () => {
                router.push({ path: '/CIM_CM0130E'});
            },

            // 아이디 중복확인
            duplicateEmplID: () => {

                if (state.dataTable.cimsUserDetail.EmplID == null) {

                    alertManager.alert.warning("아이디를 입력해주세요.");
                }
                else {

                    // 아이디 정규 체크
                    if (common.idRegularExpression(state.dataTable.cimsUserDetail.EmplID) === false) return

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
    
                    cimsUserDetail.getUserDetail(state.dataTable.cimsUserDetail.EmplID).then(result => {
    
                        if (!!result.Table.rows.length) {
    
                            alertManager.alert.warning("아이디가 중복되었습니다.");
                            state.dataTable.isDuplicate = false;
                            state.dataTable.cimsUserDetail.EmplID = null;
                        } else {
                            
                            state.dataTable.isDuplicate = true;
                            alertManager.alert.success("사용가능한 아이디입니다.");
                        }
                    }).finally(() => {
    
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                }
            },

            // 아이디 변경 메서드
            changeEmplID: () => {

                state.dataTable.isDuplicate = false;
            },

            // 사용자 관리 유효성 검사
            userValidation: () => {

                let validator = state.dataTable.cimsUserDetail;

                // 아이디
                if (!validator.EmplID) {

                    emplID.value.focus();
                    alertManager.alert.warning("아이디를 입력해주세요.");
                    return false;
                }
                // 비밀번호
                else if (!validator.Password) {

                    password.value.focus();
                    alertManager.alert.warning("비밀번호를 입력해주세요.");
                    return false;
                }
                // 비밀번호 정규식 - 글자수 8글자 제한, 영어 숫자 특수문자 조합
                else if (!common.passwordRegularExpression(validator.Password)) {
                    return;
                }
                // 성명
                else if (!validator.EmplName) {

                    emplName.value.focus();
                    alertManager.alert.warning("성명을 입력해주세요.");
                    return false;
                }
                else {
                    return true;
                }
            },
        }

        const eventHandler = {

        }

        onMounted(() => {

            // cims 사용자정보 상세 최초조회
            methods.getCimsUserDetail();

            // 프로젝트 목록 최초조회
            methods.getProject();
        });

        return {
            state,
            methods,
            eventHandler,
            common,
            cimsUserDetail,
            emplID,
            emplName,
            password,
        }
    }
}
</script>